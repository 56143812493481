.switch-with-label{
    column-gap: 4px;
  }
  .switch-btn{
          border-radius: 96px;
          background: #434343;
          display: inline-flex;
          align-items: center;
      .checkbox {
          opacity: 0;
          position: absolute;
          &:checked{
              & + .checkbox-label {
                div{
                  &:first-child{
                    opacity: 1;
                  }
                  &:last-child{
                    opacity: 0;
                  }
                }
                .ball{
                  transform: translateX(24px);
                  background-color: #00ff8d;
                }
              }
              
          }
        }
        .checkbox-label {
          width: 50px;
          height: 26px;
          border-radius: 50px;
          position: relative;
          padding: 2px;
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .ball {
              background-color: #fff;
              width: 22px;
              height: 22px;
              position: absolute;
              display: flex;
              align-items: center;
              justify-content: center;
              left: 2px;
              top: 2px;
              border-radius: 50%;
              transition: transform 0.2s linear;
            }
            div{
              padding: 0px 2px;
              font-size: var(--medium-fontsize);
              font-style: normal;
              border: none;
              line-height: normal;
              transition: all 0.3s ease-in-out;
              color: var(--desc-color);
              display: inline-flex;
              align-items: center;
              justify-content: center;
              &:first-child{
                opacity: 0;
              }
            }
        }
  }
  
    .checkbox-wrapper-4 * {
      box-sizing: border-box;
    }
    .checkbox-wrapper-4 .cbx {
      -webkit-user-select: none;
      user-select: none;
      cursor: pointer;
      padding: 6px 8px;
      border-radius: 6px;
      overflow: hidden;
      transition: all 0.2s ease;
      display: inline-block;
    }
    .checkbox-wrapper-4 .cbx:not(:last-child) {
      margin-right: 6px;
    }
    .checkbox-wrapper-4 .cbx:hover {
      background: rgba(0,119,255,0.06);
    }
    .checkbox-wrapper-4 .cbx span {
      float: left;
      vertical-align: middle;
      transform: translate3d(0, 0, 0);
    }
    .checkbox-wrapper-4 .cbx span:first-child {
      position: relative;
      width: 18px;
      height: 18px;
      border-radius: 4px;
      transform: scale(1);
      border: 1px solid #cccfdb;
      transition: all 0.2s ease;
      box-shadow: 0 1px 1px rgba(0,16,75,0.05);
    }
    .checkbox-wrapper-4 .cbx span:first-child svg {
          /* position: absolute; */
      /* top: 3px; */
      /* left: 2px; */
      opacity: 0;
      position: relative;
      // left: 3px;
      /* fill: none; */
      stroke: #fff;
      stroke-linecap: round;
      stroke-width: 0;
      /* stroke-linejoin: round; */
      /* stroke-dasharray: 16px; */
      /* stroke-dashoffset: 16px; */
      /* transition: all 0.3s ease; */
      /* transition-delay: 0.1s; */
      /* transform: translate3d(0, 0, 0); */
    }
    .checkbox-wrapper-4 .cbx span:last-child {
      padding-left: 8px;
      line-height: 18px;
    }
    .checkbox-wrapper-4 .cbx:hover span:first-child {
      border-color: #07f;
    }
    .checkbox-wrapper-4 .inp-cbx {
      position: absolute;
      visibility: hidden;
    }
    .checkbox-wrapper-4 .inp-cbx:checked + .cbx span:first-child {
      background: #07f;
      border-color: #07f;
      animation: wave-4 0.4s ease;
    }
    .checkbox-wrapper-4 .inp-cbx:checked + .cbx span:first-child svg {
      stroke-dashoffset: 0;
      opacity: 1;
    }
    .checkbox-wrapper-4 .inline-svg {
      position: absolute;
      width: 0;
      height: 0;
      pointer-events: none;
      user-select: none;
    }
    @media screen and (max-width: 640px) {
      .checkbox-wrapper-4 .cbx {
        width: 100%;
        display: inline-block;
      }
    }
    @-moz-keyframes wave-4 {
      50% {
        transform: scale(0.9);
      }
    }
    @-webkit-keyframes wave-4 {
      50% {
        transform: scale(0.9);
      }
    }
    @-o-keyframes wave-4 {
      50% {
        transform: scale(0.9);
      }
    }
    @keyframes wave-4 {
      50% {
        transform: scale(0.9);
      }
    }
  