.smart-input{
    margin-bottom: 18px;
    display: flex;
    flex-direction: column;
    position: relative;
    .settings-link{
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 2%;
            margin: 0px 0px;
            cursor: pointer;
        span{
            color: #FFF;
            font-family: "SF Pro Display";
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            &:first-child{
                display: inline-flex;
                align-items: center;
                justify-content: center;
                column-gap: 8px;
            }
        }
        &.active{
            >span{
                &:last-child{
                    transform: rotate(180deg);
                }
            }
        }
    }
    &>label{
    color: #FFF;
    font-family: "SF Pro Display";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 8px;
    }
    &>.smart-input-cover{
        border-radius: 2px;
            border: 1px solid var(--border-color);
            background: #141414;
            display: inline-flex;
            align-items: center;
            .tip{
                cursor: pointer;
                position: relative;
                .tip-data{
                    position: absolute;
                    width: 40vw;
                }
            }

        &:has(input.percent){
            &::after{
                content: "%";
                position: absolute;
                right: 10px;
                left: auto;
                color: rgba(247, 255, 247, 0.6);
                font-family: "SF Pro Display";
                font-size: 16px;
                font-style: normal;
                font-weight: 300;
            }
        }
        &:has(input.money){
            &::after{
                content: "€";
                position: absolute;
                right: 10px;
                left: auto;
                color: rgba(247, 255, 247, 0.6);
                font-family: "SF Pro Display";
                font-size: 16px;
                font-style: normal;
                font-weight: 300;
            }
        }
        input{
            background: #141414;
            width: 100%;
            border: none;
            color: rgba(247, 255, 247, 0.60);
                font-family: "SF Pro Display";
                font-size: 16px;
                font-style: normal;
                font-weight: 300;
                line-height: normal;
                padding: 16px 16px;
                &:focus, &:focus-visible, &:focus-within{
                    outline: none;
                }
                
        }
        .range-selected{
            padding: 10px 0px;
        }
            button{
                color: #FFF;
                font-family: "SF Pro Display";
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
            .acc-newselectwrapper{
                width: 100%;
                .acc-newselect__control {
                    background-color: transparent;
                    border: none;
                    min-height: initial;
                    padding: 10px 0px;
                    border-radius: 0;
                }
            }
    }
    .datepicker-wrapper{
        &.dark-calendar-wrapper{
            top: 73px;
        }
        
    }
}

@media (max-width: 768px){
    .smart-input > .smart-input-cover input {
        padding: 12px 12px;
    }
}